import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Input } from "reactstrap";
import { loginSubmit, updateFormInput } from "../../store";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

function LoginForm() {
  const dispatch = useDispatch();
  const user = useSelector(({ login }) => login.loginForm.user);
  const password = useSelector(({ login }) => login.loginForm.password);
  const isLoading = useSelector(({ login }) => login.loginForm.isLoading);
  const handleChange = ({ target }) => {
    const { value, name } = target;
    dispatch(updateFormInput({ value, name, page: "loginForm" }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginSubmit());
  };
  return (
    <Card>
      <Logo />
      <CardBody tag="form" className="d-grid gap-3" onSubmit={handleSubmit}>
        <Input
          type="text"
          name="user"
          placeholder="User"
          value={user}
          onChange={handleChange}
        />
        <Input
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={handleChange}
        />
        <Button
          color="primary"
          size="lg"
          block
          type="submit"
          disabled={isLoading}
        >
          Log in
        </Button>
      </CardBody>
    </Card>
  );
}

export default LoginForm;
