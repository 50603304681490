import { createApi } from "@reduxjs/toolkit/query/react";
import { API } from "aws-amplify";
import { extractionsByTypeAndCreatedAt } from "../../graphql/custom-queries";
import { customCreateExtraction } from "../../graphql/mutations";

const baseQuery = async (graphqlParams) => {
  try {
    const result = await API.graphql(graphqlParams);
    return result;
  } catch (error) {
    return { error: { status: error.response?.status, data: error } };
  }
};

const windirCloudApi = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: ["extractions", "accounts"],
  endpoints: (builder) => ({
    listExtractions: builder.query({
      query: (searchParams) => ({
        query: extractionsByTypeAndCreatedAt,
        variables: {
          type: "Extraction",
          sortDirection: "DESC",
          limit: 20,
          ...searchParams,
        },
      }),
      transformResponse: (response) => response.extractionsByTypeAndCreatedAt,
      providesTags: ["extractions"],
    }),
    createExtraction: builder.mutation({
      queryFn: (group, { getState }) => {
        const input = {
          ...getState().extractions.addExtractionModal.form,
          groups: ["admin", group],
          source: "ACCOUNT_POSTS",
        };

        if (input.media === "INSTAGRAM") {
          input.source = getState().extractions.addExtractionModal.form.source;
        }

        return baseQuery({
          query: customCreateExtraction,
          variables: { input },
        });
      },
      invalidatesTags: ["extractions"],
    }),
  }),
});

export const { useListExtractionsQuery, useCreateExtractionMutation } =
  windirCloudApi;

export default windirCloudApi;
