import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navSelected: "FACEBOOK",
  data: undefined,
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    changeSelectedNavAccount: (state, { payload }) => {
      state.navSelected = payload;
    },
    updateCookiesData: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { changeSelectedNavAccount, updateCookiesData } =
  accountsSlice.actions;

export default accountsSlice.reducer;
