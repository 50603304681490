import { createSlice } from "@reduxjs/toolkit";
import { aMonthAgoString, todayString } from "../../utils/constants";

const initialState = {
  paginationTokens: [],
  search: "",
  startDate: aMonthAgoString,
  endDate: todayString,
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    addNextTokenToPaginationTokens: (state, { payload }) => {
      state.paginationTokens.push(payload);
    },
    removeLastItemPaginationTokens: (state) => {
      state.paginationTokens.pop();
    },
    setFormValues: (state, { payload }) => {
      const { id, value } = payload;

      state[id] = value;
    },
  },
});

export const {
  addNextTokenToPaginationTokens,
  removeLastItemPaginationTokens,
  setFormValues,
} = filtersSlice.actions;

export default filtersSlice.reducer;
