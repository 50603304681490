import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addExtractionModal: {
    isOpen: false,
    form: {
      idAccount: "",
      media: "",
      gdpr: false,
      source: "ACCOUNT_POSTS",
    },
  },
};

const extractionsSlice = createSlice({
  name: "extractions",
  initialState,
  reducers: {
    toggleAddExtractionModal: (state) => {
      state.addExtractionModal.isOpen = !state.addExtractionModal.isOpen;
    },
    updateAddExtractionModalForm: (state, { payload }) => {
      const { id, value } = payload;

      state.addExtractionModal.form[id] = value;
    },
    resetAddExtractionModalForm: () => initialState,
  },
});

export const {
  toggleAddExtractionModal,
  updateAddExtractionModalForm,
  resetAddExtractionModalForm,
} = extractionsSlice.actions;

export default extractionsSlice.reducer;
