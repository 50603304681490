import { Hub } from "aws-amplify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateIsLoggedIn } from "../store";

const useAuthEventListener = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/admin";

  const events = {
    signIn: () => {
      navigate(from, { replace: true });
    },
    signOut: () => {
      dispatch(updateIsLoggedIn(false));
      navigate("/login");
    },
  };

  const listener = ({ payload }) => {
    events[payload.event]?.();
  };
  useEffect(() => {
    const unsubscribe = Hub.listen("auth", listener);

    return () => unsubscribe();
  }, []);
};

export default useAuthEventListener;
