import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Home, Login } from "./pages";
import { ProtectedRoute } from "./components";
import { useAuthEventListener } from "./utils";

const Admin = lazy(() => import("./pages/admin"));

function App() {
  useAuthEventListener();
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="admin/*"
        element={
          <ProtectedRoute>
            <Suspense fallback={<div>Loading...</div>}>
              <Admin />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="home/*"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route index element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default App;
