import { Storage } from "aws-amplify";
import { useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import { ReactComponent as DownloadIcon } from "../../../assets/images/download-icon.svg";
import { useListExtractionsQuery } from "../../../store";
import { MEDIAS_FORMATED, PROGRESS_STATUS_FORMATED } from "../../../utils";
import { LoadingTable } from "../../../components";

const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

const download = async ({ currentTarget }) => {
  const { filepath } = currentTarget.dataset;
  const filename = `${filepath}.gephi`;
  const result = await Storage.get(`${filepath}.gephi`, { download: true });
  downloadBlob(result.Body, filename);
};

function DataTable() {
  const nextToken = useSelector(({ filters }) =>
    filters.paginationTokens?.at(-1)
  );
  const startDate = useSelector(({ filters }) => filters.startDate);
  const endDate = useSelector(({ filters }) => filters.endDate);
  const search = useSelector(({ filters }) => filters.search);

  const { data, isFetching, isSuccess } = useListExtractionsQuery(
    {
      nextToken,
      createdAt: { between: [startDate, endDate] },
      filter: { idAccount: { contains: search } },
    },
    {
      selectFromResult: (result) => ({
        data: result.data?.items,
        isSuccess: result.isSuccess,
        isFetching: result.isFetching,
      }),
    }
  );
  if (isFetching) {
    return (
      <Table striped responsive hover>
        <thead>
          <tr>
            <th scope="col">ID Account</th>
            <th scope="col">Media</th>
            <th scope="col">Anonimized data</th>
            <th scope="col">Request date</th>
            <th scope="col">Progress</th>
            <th scope="col">Upload</th>
          </tr>
        </thead>
        <tbody>
          <LoadingTable />
        </tbody>
      </Table>
    );
  }
  return (
    <Table striped responsive hover>
      <thead>
        <tr>
          <th scope="col">ID Account</th>
          <th scope="col">Media</th>
          <th scope="col">Anonimized data</th>
          <th scope="col">Request date</th>
          <th scope="col">Progress</th>
          <th scope="col">Download</th>
        </tr>
      </thead>
      <tbody>
        {isSuccess &&
          data.map(
            ({ id, idAccount, media, gdpr, createdAt, progress, filepath }) => (
              <tr key={id}>
                <td>{idAccount}</td>
                <td>{MEDIAS_FORMATED[media]}</td>
                <td className="text-center">{gdpr ? "✅" : "𐄂"}</td>
                <td>{createdAt}</td>
                <td>{PROGRESS_STATUS_FORMATED[progress]}</td>
                <td>
                  {filepath && (
                    <Button onClick={download} data-filepath={id}>
                      <DownloadIcon />
                    </Button>
                  )}
                </td>
              </tr>
            )
          )}
      </tbody>
    </Table>
  );
}

export default DataTable;
