import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

const initialState = {
  loginForm: {
    user: "",
    password: "",
    isLoading: false,
  },
  newPasswordModal: {
    isOpen: false,
    newPassword: "",
    confirmPassword: "",
  },
  user: {
    data: undefined,
    isLoggedIn: false,
  },
};
export const loginSubmit = createAsyncThunk(
  "login/loginSubmit",
  (_, { getState }) => {
    const { user, password } = getState().login.loginForm;
    return Auth.signIn(user, password);
  },
  {
    condition: (_, { getState }) => {
      const { user, password } = getState().login.loginForm;
      if (!user || !password) {
        return false;
      }
      return true;
    },
  }
);
export const getCurrentUser = createAsyncThunk(
  "login/getCurrentUser",
  async () => Auth.currentAuthenticatedUser()
);

export const confirmNewPassword = createAsyncThunk(
  "login/confirmNewPassword",
  async (_, { getState }) => {
    const { login } = getState();
    const { newPassword } = login.newPasswordModal;
    const user = login.user.data;
    return Auth.completeNewPassword(user, newPassword);
  },
  {
    condition: (_, { getState }) => {
      const { newPassword, confirmPassword } =
        getState().login.newPasswordModal;
      if (newPassword !== confirmPassword) {
        return false;
      }
      return true;
    },
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.newPasswordModal.isOpen = false;
    },
    updateIsLoggedIn: (state, { payload }) => {
      state.user.isLoggedIn = payload;
      if (payload === false) {
        state.user.data = undefined;
      }
    },
    updateFormInput: (state, { payload }) => {
      const { value, name, page } = payload;
      state[page][name] = value.trim();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginSubmit.pending, (state) => {
      state.loginForm.isLoading = true;
    });
    builder.addCase(loginSubmit.rejected, (state) => {
      state.loginForm = initialState.loginForm;
    });
    builder.addCase(loginSubmit.fulfilled, (state, { payload }) => {
      state.loginForm.isLoading = false;
      state.user.data = payload;
      if (payload?.challengeName === "NEW_PASSWORD_REQUIRED") {
        state.newPasswordModal.isOpen = true;
        return;
      }

      state.loginForm = initialState.loginForm;
      state.user.isLoggedIn = true;
    });
    builder.addCase(confirmNewPassword.fulfilled, (state, { payload }) => {
      state.newPasswordModal.isOpen = false;
      state.user.data = payload;
      state.user.isLoggedIn = true;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      state.user.isLoggedIn = true;
      state.user.data = payload;
    });
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.user.isLoggedIn = false;
      state.user.data = undefined;
    });
  },
});

export const { closeModal, updateIsLoggedIn, updateFormInput } =
  loginSlice.actions;

export default loginSlice.reducer;
