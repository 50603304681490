import { createSelector } from "@reduxjs/toolkit";

const userTypes = {
  admin: "admin",
  client: "client",
  gdpr: "gdpr",
};

const userTypesArray = Object.keys(userTypes);

export const userTypeSelector = createSelector(
  ({ login }) => login.user.data?.signInUserSession.accessToken.payload,
  (userData) => {
    let userType = userTypes.client;
    if (userData?.["cognito:groups"]?.includes(userTypes.admin)) {
      userType = userTypes.admin;
    }
    return userType;
  }
);

export const groupSelector = createSelector(
  ({ login }) => login.user.data?.signInUserSession.accessToken.payload,
  (userData) => {
    const userGroup = userData?.["cognito:groups"]?.find(
      (group) => !userTypesArray.includes(group)
    );

    return userGroup;
  }
);

export const isGdprSelector = createSelector(
  ({ login }) => login.user.data?.signInUserSession.accessToken.payload,
  (userData) => userData?.["cognito:groups"]?.includes(userTypes.gdpr)
);
