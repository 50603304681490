import AddExtractionModal from "./add-extraction-modal";
import DataTable from "./data-table";
import TableBar from "./table-bar";

function Extractions() {
  return (
    <>
      <TableBar />
      <DataTable />
      <AddExtractionModal />
      <TableBar />
    </>
  );
}

export default Extractions;
