import { configureStore, combineSlices } from "@reduxjs/toolkit";
import login from "./slices/login";
import extractions from "./slices/extractions";
import windirCloudApi from "./slices/windir-cloud-api";
import accounts from "./slices/accounts";
import filters from "./slices/filters";

export const rootReducer = combineSlices({
  login,
  extractions,
  accounts,
  filters,
  [windirCloudApi.reducerPath]: windirCloudApi.reducer,
});

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload"],
        ignoredPaths: [
          "login.user.data",
          "extractionsAdmin.confirmUploadModal.file",
        ],
      },
    }).concat(windirCloudApi.middleware),
});
