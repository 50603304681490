import { Amplify } from "aws-amplify";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { getCurrentUser, store } from "./store";
import awsExports from "./aws-exports";
import "./assets/styles/custom.scss";

Amplify.configure(awsExports);
store.dispatch(getCurrentUser());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>
);
