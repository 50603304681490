/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const customCreateExtraction = /* GraphQL */ `
  mutation CustomCreateExtraction($input: CustomCreateExtractionInput!) {
    customCreateExtraction(input: $input) {
      idAccount
      media
      source
      progress
      type
      createdAt
      groups
      filepath
      owner
      gdpr
      id
      updatedAt
      __typename
    }
  }
`;
export const updateExtractionInProgress = /* GraphQL */ `
  mutation UpdateExtractionInProgress($input: CustomUpdateExtractionInput!) {
    updateExtractionInProgress(input: $input) {
      idAccount
      media
      source
      progress
      type
      createdAt
      groups
      filepath
      owner
      gdpr
      id
      updatedAt
      __typename
    }
  }
`;
export const updateExtractionReadyToDownload = /* GraphQL */ `
  mutation UpdateExtractionReadyToDownload(
    $input: CustomUpdateExtractionInput!
  ) {
    updateExtractionReadyToDownload(input: $input) {
      idAccount
      media
      source
      progress
      type
      createdAt
      groups
      filepath
      owner
      gdpr
      id
      updatedAt
      __typename
    }
  }
`;
export const createExtraction = /* GraphQL */ `
  mutation CreateExtraction(
    $input: CreateExtractionInput!
    $condition: ModelExtractionConditionInput
  ) {
    createExtraction(input: $input, condition: $condition) {
      idAccount
      media
      source
      progress
      type
      createdAt
      groups
      filepath
      owner
      gdpr
      id
      updatedAt
      __typename
    }
  }
`;
export const updateExtraction = /* GraphQL */ `
  mutation UpdateExtraction(
    $input: UpdateExtractionInput!
    $condition: ModelExtractionConditionInput
  ) {
    updateExtraction(input: $input, condition: $condition) {
      idAccount
      media
      source
      progress
      type
      createdAt
      groups
      filepath
      owner
      gdpr
      id
      updatedAt
      __typename
    }
  }
`;
export const deleteExtraction = /* GraphQL */ `
  mutation DeleteExtraction(
    $input: DeleteExtractionInput!
    $condition: ModelExtractionConditionInput
  ) {
    deleteExtraction(input: $input, condition: $condition) {
      idAccount
      media
      source
      progress
      type
      createdAt
      groups
      filepath
      owner
      gdpr
      id
      updatedAt
      __typename
    }
  }
`;
