export const listExtractions = /* GraphQL */ `
  query ListExtractions(
    $filter: ModelExtractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtractions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idAccount
        media
        progress
        createdAt
        filepath
      }
      nextToken
    }
  }
`;
export const extractionsByTypeAndCreatedAt = /* GraphQL */ `
  query ExtractionsByTypeAndCreatedAt(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExtractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    extractionsByTypeAndCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        idAccount
        media
        gdpr
        progress
        createdAt
        filepath
      }
      nextToken
    }
  }
`;
