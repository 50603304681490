import { Auth } from "aws-amplify";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Button,
  Collapse,
  Navbar as NavbarComponent,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap";

const LINKS = [];

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavbar = () => setIsOpen(!isOpen);
  const handleClick = () => Auth.signOut();

  return (
    <NavbarComponent expand="md" color="info" dark>
      <NavbarBrand to="/" tag={Link}>
        Windir
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} />
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar className="ms-auto">
          {LINKS.map(({ to, children }) => (
            <NavItem key={to}>
              <NavLink to={to} className="nav-link">
                {children}
              </NavLink>
            </NavItem>
          ))}
          <NavItem>
            <Button color="danger" block type="button" onClick={handleClick}>
              Log out
            </Button>
          </NavItem>
        </Nav>
      </Collapse>
    </NavbarComponent>
  );
}

export default Navbar;
