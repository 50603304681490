export const HOME_PATH = "/home";

export const MEDIAS = ["FACEBOOK", "INSTAGRAM", "TIKTOK"];

export const MEDIAS_FORMATED = {
  FACEBOOK: "Facebook",
  INSTAGRAM: "Instagram",
  TIKTOK: "TikTok",
};

export const PROGRESS_STATUS_FORMATED = {
  REQUEST: "Request",
  IN_PROGRESS: "In progress",
  READY_TO_DOWNLOAD: "Ready to download",
};

export const today = new Date();
today.setDate(today.getDate() + 1);
export const todayString = today.toISOString().slice(0, 10);
export const aMonthAgo = new Date();
aMonthAgo.setMonth(today.getMonth() - 1);
export const aMonthAgoString = aMonthAgo.toISOString().slice(0, 10);
