import { useDispatch } from "react-redux";
import { Button, InputGroup, Row } from "reactstrap";
import { setFormValues, toggleAddExtractionModal } from "../../../store";
import { Filters } from "../../../components";
import { debounce } from "../../../utils";

function TableBar() {
  const dispatch = useDispatch();
  const toggle = () => dispatch(toggleAddExtractionModal());

  const handleChange = ({ target }) => {
    const { name, value } = target;
    dispatch(setFormValues({ id: name, value }));
  };
  const debounceHandleChange = debounce(handleChange, 300);

  return (
    <Row>
      <InputGroup tag="form" onChange={debounceHandleChange}>
        <Button onClick={toggle}>Add new extraction</Button>
        <Filters />
      </InputGroup>
    </Row>
  );
}

export default TableBar;
