import { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginForm from "./login-form";
import ConfirmPasswordModal from "./confirm-password-modal";

const home = "/home";

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(({ login }) => login.user.isLoggedIn);

  let from = home;
  if (location.state?.from?.pathname.startsWith?.(from)) {
    from = location.state?.from?.pathname;
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate(from, { replace: true });
    }
  }, [isLoggedIn, navigate, from]);

  return (
    <Container fluid className="login overflow-hidden position-absolute">
      <div className="top" />
      <div className="bottom" />
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col md={6} lg={4}>
          <LoginForm />
        </Col>
      </Row>
      <ConfirmPasswordModal />
    </Container>
  );
}

export default Login;
