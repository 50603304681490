import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  InputGroupText,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  addNextTokenToPaginationTokens,
  removeLastItemPaginationTokens,
} from "../store";
import windirCloudApi from "../store/slices/windir-cloud-api";

function Next() {
  const dispatch = useDispatch();
  const currentToken = useSelector(({ filters }) =>
    filters.paginationTokens?.at(-1)
  );
  const startDate = useSelector(({ filters }) => filters.startDate);
  const endDate = useSelector(({ filters }) => filters.endDate);
  const search = useSelector(({ filters }) => filters.search);

  const newToken = windirCloudApi.endpoints.listExtractions.useQueryState(
    {
      nextToken: currentToken,
      createdAt: { between: [startDate, endDate] },
      filter: { idAccount: { contains: search } },
    },
    { selectFromResult: ({ data }) => data?.nextToken }
  );

  const handleClick = ({ currentTarget }) => {
    const { nexttoken } = currentTarget.dataset;
    dispatch(addNextTokenToPaginationTokens(nexttoken));
  };

  return (
    <PaginationItem>
      <PaginationLink
        next
        tag="button"
        type="button"
        data-nexttoken={newToken}
        disabled={!newToken}
        onClick={handleClick}
      >
        Next
      </PaginationLink>
    </PaginationItem>
  );
}

function Previous() {
  const dispatch = useDispatch();
  const isDisabled = useSelector(
    ({ filters }) => !filters.paginationTokens.length
  );
  const handleClick = () => {
    dispatch(removeLastItemPaginationTokens());
  };
  return (
    <PaginationItem>
      <PaginationLink
        previous
        className="rounded-start-0"
        tag="button"
        type="button"
        onClick={handleClick}
        disabled={isDisabled}
      >
        Previous
      </PaginationLink>
    </PaginationItem>
  );
}
function Filters() {
  const searchValue = useSelector(({ filters }) => filters.search);
  const startDateValue = useSelector(({ filters }) => filters.startDate);
  const endDateValue = useSelector(({ filters }) => filters.endDate);
  const searchRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  useEffect(() => {
    searchRef.current.value = searchValue;
  }, [searchValue]);
  useEffect(() => {
    startDateRef.current.value = startDateValue;
  }, [startDateValue]);
  useEffect(() => {
    endDateRef.current.value = endDateValue;
  }, [endDateValue]);
  return (
    <>
      <Input
        name="search"
        type="search"
        placeholder="Search ID account"
        innerRef={searchRef}
      />
      <InputGroupText className="d-none d-md-flex">Date range</InputGroupText>
      <Input
        name="startDate"
        type="date"
        innerRef={startDateRef}
        max={endDateValue}
      />
      <Input
        name="endDate"
        type="date"
        innerRef={endDateRef}
        min={startDateValue}
      />
      <Pagination listClassName="mb-0">
        <Previous />
        <Next />
      </Pagination>
    </>
  );
}

export default Filters;
