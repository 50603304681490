import { node } from "prop-types";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { userTypeSelector } from "../store";

const redirections = {
  admin: "/admin",
  client: "/home",
};

function ProtectedRoute({ children }) {
  const location = useLocation();
  const isLoggedIn = useSelector(({ login }) => login.user.isLoggedIn);
  const userType = useSelector(userTypeSelector);
  if (isLoggedIn) {
    if (location.pathname.startsWith(redirections[userType])) {
      return children;
    }
    return (
      <Navigate
        to={redirections[userType]}
        state={{ from: location }}
        replace
      />
    );
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
}

ProtectedRoute.propTypes = {
  children: node.isRequired,
};

export default ProtectedRoute;
