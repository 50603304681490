import {
  Modal,
  Form,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, confirmNewPassword, updateFormInput } from "../../store";

function ConfirmPasswordModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector(({ login }) => login.newPasswordModal.isOpen);
  const newPassword = useSelector(
    ({ login }) => login.newPasswordModal.newPassword
  );
  const confirmPassword = useSelector(
    ({ login }) => login.newPasswordModal.confirmPassword
  );
  const handleChange = ({ target }) => {
    const { value, name } = target;
    dispatch(updateFormInput({ value, name, page: "newPasswordModal" }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(confirmNewPassword());
  };
  return (
    <Modal isOpen={isOpen} size="sm" backdrop="static" centered>
      <Form onSubmit={handleSubmit}>
        <ModalHeader>Change password</ModalHeader>
        <ModalBody className="d-grid gap-3">
          <Input
            type="password"
            name="newPassword"
            placeholder="Set new password"
            value={newPassword}
            onChange={handleChange}
          />
          <Input
            type="password"
            name="confirmPassword"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={handleChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              dispatch(closeModal());
            }}
          >
            Cancel
          </Button>
          <Button type="submit" color="warning">
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default ConfirmPasswordModal;
