import { Container } from "reactstrap";
import { Navigate, Route, Routes } from "react-router-dom";
import { Navbar } from "../../components";
import Extractions from "./extractions";

function Home() {
  return (
    <>
      <Navbar />
      <Container>
        <Routes>
          <Route path="extractions" element={<Extractions />} />
          <Route index element={<Navigate to="extractions" />} />
        </Routes>
      </Container>
    </>
  );
}

export default Home;
