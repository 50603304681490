import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import {
  groupSelector,
  isGdprSelector,
  resetAddExtractionModalForm,
  toggleAddExtractionModal,
  updateAddExtractionModalForm,
  useCreateExtractionMutation,
} from "../../../store";
import { MEDIAS_FORMATED } from "../../../utils";

function InstagramOptions() {
  const dispatch = useDispatch();
  const source = useSelector(
    ({ extractions }) => extractions.addExtractionModal.form.source
  );
  const handleChange = ({ target }) => {
    const { value, name } = target;

    dispatch(
      updateAddExtractionModalForm({
        value,
        id: name,
      })
    );
  };
  return (
    <FormGroup tag="fieldset" value={source} onChange={handleChange}>
      <legend>Select a source</legend>
      <FormGroup check>
        <Input
          name="source"
          id="accountPosts"
          type="radio"
          value="ACCOUNT_POSTS"
          checked={source === "ACCOUNT_POSTS"}
        />{" "}
        <Label check htmlFor="accountPosts">
          Account posts
        </Label>
      </FormGroup>
      <FormGroup check>
        <Input
          name="source"
          id="hashtag"
          type="radio"
          value="HASHTAG"
          checked={source === "HASHTAG"}
        />{" "}
        <Label check htmlFor="hashtag">
          Hashtag
        </Label>
      </FormGroup>
    </FormGroup>
  );
}

function AddExtractionModal() {
  const dispatch = useDispatch();
  const [startExtraction, { isLoading, isSuccess, isError }] =
    useCreateExtractionMutation();
  const isOpen = useSelector(
    ({ extractions }) => extractions.addExtractionModal.isOpen
  );
  const idAccount = useSelector(
    ({ extractions }) => extractions.addExtractionModal.form.idAccount
  );
  const media = useSelector(
    ({ extractions }) => extractions.addExtractionModal.form.media
  );
  const gdpr = useSelector(
    ({ extractions }) => extractions.addExtractionModal.form.gdpr
  );
  const group = useSelector(groupSelector);
  const isGdpr = useSelector(isGdprSelector);
  useEffect(() => {
    if (isGdpr) {
      dispatch(updateAddExtractionModalForm({ value: true, id: "gdpr" }));
    }
  }, [isGdpr]);

  const toggle = () => dispatch(toggleAddExtractionModal());
  const handleChange = ({ currentTarget }) => {
    const { value, type, checked, id } = currentTarget;

    dispatch(
      updateAddExtractionModalForm({
        value: type === "checkbox" ? checked : value,
        id,
      })
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const request = startExtraction(group);
    setTimeout(() => {
      toggle();
      dispatch(resetAddExtractionModalForm());
      if (isGdpr) {
        dispatch(updateAddExtractionModalForm({ value: true, id: "gdpr" }));
      }
      request.reset();
    }, 4000);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Request new extraction</ModalHeader>
      <ModalBody tag="form" onSubmit={handleSubmit}>
        <FormGroup floating>
          <Input
            placeholder="Account"
            id="idAccount"
            type="text"
            onChange={handleChange}
            value={idAccount}
            disabled={isLoading}
            required
          />
          <Label for="idAccount">Account (url)</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            placeholder="Media"
            id="media"
            type="select"
            value={media}
            onChange={handleChange}
            disabled={isLoading}
            required
          >
            <option defaultValue value="" hidden>
              Select an option
            </option>
            {Object.entries(MEDIAS_FORMATED).map(([id, value]) => (
              <option key={id} value={id}>
                {value}
              </option>
            ))}
          </Input>
          <Label for="account">Media</Label>
        </FormGroup>
        {media === "INSTAGRAM" && <InstagramOptions />}
        <FormGroup check>
          <Input
            id="gdpr"
            type="checkbox"
            checked={gdpr}
            onChange={handleChange}
            disabled={isGdpr}
            aria-describedby="gdpr-compliance"
          />
          <Label for="gdpr">Anonimize data</Label>
          {isGdpr && (
            <FormText className="d-block" id="gdpr-compliance">
              You can&apos;t uncheck this option cause your&apos;re required to
              acomplish this requirement (GDPR compliance)
            </FormText>
          )}
        </FormGroup>
        {isSuccess && <Alert>Request created successfully</Alert>}
        {isError && (
          <Alert color="danger">
            There was an error while creating the request please reach
            marcoantonio@antropomedia for more help
          </Alert>
        )}
        <Button
          block
          disabled={isLoading}
          type="submit"
          color="antropomedia-red"
        >
          Add
        </Button>
      </ModalBody>
    </Modal>
  );
}

export default AddExtractionModal;
