const ROWS_LENGTH = 20;
function LoadingTable() {
  const rows = [];
  for (let index = 0; index < ROWS_LENGTH; index += 1) {
    rows.push(
      <tr key={index}>
        <td className="placeholder-glow" aria-label="loading indicator">
          <span className="placeholder col-12" />
        </td>
        <td className="placeholder-glow" aria-label="loading indicator">
          <span className="placeholder col-12" />
        </td>
        <td className="placeholder-glow" aria-label="loading indicator">
          <span className="placeholder col-12" />
        </td>
        <td className="placeholder-glow" aria-label="loading indicator">
          <span className="placeholder col-12" />
        </td>
        <td className="placeholder-glow" aria-label="loading indicator">
          <span className="placeholder col-12" />
        </td>
        <td className="placeholder-glow" aria-label="loading indicator">
          <span className="placeholder col-12" />
        </td>
      </tr>
    );
  }
  return rows;
}

export default LoadingTable;
